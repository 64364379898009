















































































import Dealer from '@/models/Dealer.model';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import { namespace } from 'vuex-class';
import { DealerStoreActions } from '@/store/dealer.store';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const DealerStore = namespace('dealer');

@Component({
  components: {
    EditDealerComponent: () => import(
      /* webpackChunkName: "EditDealerComponent" */
      '@/components/EditDealer.component.vue'
    )
  }
})
export default class DealerPropertiesComponent extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public dealers!: Dealer[];

  @Prop({ default: false })
  private loading!: boolean;

  @Prop({ required: true })
  private reloadDealers!: () => void;

  @DealerStore.Action(DealerStoreActions.RESEND_SET_PASSWORD)
  private resendSetPasswordAction!: (dealerId: string) => Promise<any>;

  private showCreationDialog: boolean = false;
  private showEditDialog: boolean = false;

  private selectedDealer?: Dealer;

  private async closeDialog(reload: boolean) {
    if (reload) {
      this.reloadDealers();
    }
    this.showCreationDialog = false;
    this.showEditDialog = false;
  }

  /**
   * Creates new dealer and sets store to current store.
   */
  get newDealer(): Dealer {
    const dealer = new Dealer();
    dealer.store = this.currentStore;
    return dealer;
  }

  public startEditing(dealer: Dealer) {
    this.showEditDialog = true;
    this.selectedDealer = dealer;
  }

  private resendSetPassword(dealerId: string) {
    try {
      this.resendSetPasswordAction(dealerId);
      this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.RESEND_PASSWORD_MAIL_SENT');
    } catch (e) {
      this.handleAxiosError(e);
    }
  }
}
